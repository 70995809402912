import { useEffect, useState } from 'react';
import { RootValuesState, initialValuesState, useTrackTitanStore } from './store';

export function useTrackTitanHydratedStore<T>(stateFunction: (state: RootValuesState) => T): T {
    const [state, setState] = useState<T>(stateFunction(initialValuesState));
    const zustandState = useTrackTitanStore(stateFunction);

    useEffect(() => {
        setState(zustandState);
    }, [zustandState]);

    return state;
}
